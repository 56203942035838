#header #banner-container
{
	background-image: url(./images/sides.jpg);
	background-repeat: repeat-x;
}

#header #banner-container img#banner
{
	display: block;
	margin-left: auto;
	margin-right: auto;
}

#header #banner-container #banner
{
	display: block;
	margin-left: auto;
	margin-right: auto;
	background-image: url(./images/header.jpg);
	background-repeat: no-repeat;
	background-position: center;
	height: 119px;
	color: White;
	position: relative;
}


#header #banner-container #banner #address-container
{
	padding-right: 80px;
	padding-top: 8px;
	height: 100%;
	float:right;
}


#header #banner-container #banner #address-container a
{
	text-decoration: none;
	color: White;
}

#header #banner-container #banner #home-link-overlay
{
	display: block;
	height: 90px;
	width: 180px;
	padding: 0;
	margin-left: 60px;
	float:left;
}

#header #banner-container #banner #home-link-overlay a
{
	display: block;
	height: 100%;
	width: 100%;
	text-decoration: none;
}


#header #banner-container #banner #language-bar
{
	position: absolute;
	top: 80px;
	right: 80px;
	font-size: 11px;
}

#header #banner-container #banner #language-bar ul li
{
	display: inline-block;
	list-style: none;
	margin-left: 8px;
}

#header #banner-container #banner #language-bar ul li img
{
	margin: 0 2px 0 0;
	border: none;
	vertical-align: middle;
}

#header #banner-container #banner #language-bar ul li a
{
	text-decoration: none;
	color: #E0E0E0;
}

#header #banner-container #banner #language-bar ul li a:hover
{
	text-decoration: none;
	color: White;
}