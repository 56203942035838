.menu
{
	font-family: Arial;
	float: left;
	width: 185px;
}

.menu ul
{
	padding: 0;
	margin: 0;
	border-top: 1px solid White;
	width: 185px;
}

.menu ul li
{
	list-style: none;
	margin-bottom: 4px;
}

.menu ul li a
{
	color: White;
	text-transform: uppercase;
	text-decoration: none;
	background-color: #404040;
	display: block;
	border:0.5px solid #b3b3b3;
	padding-bottom: 4px;
	padding-top: 4px;
	padding-left: 24px;
}

.menu ul li a:hover
{
	background-color: Black;
}

.menu ul li a:active
{
	background-color: Black;
}

.menu ul li.selected a
{
	background-color: Black;
}

/* nested */

.menu ul ul
{
	padding-bottom: 4px;
	border-top: none;
	width: 180px; /* 5px smaller than the parent menu */
}

.menu ul ul li a
{
	color: #960000;
	text-transform: none;
	text-decoration: none;
	background-color: White ! important;
	display: block;
	border:none;
	padding-bottom: 1px;
	padding-top: 1px;
	padding-left: 36px;
	text-indent: -1em;
}

.menu ul ul li a:hover
{
	background-color: White;
	font-weight: bold;
}

.menu ul ul li a:active
{
	background-color: White;
	font-weight: bold;
}

.menu ul ul li.selected a
{
	background-color: White;
	font-weight: bold;
}
