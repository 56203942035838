/* MAIN CONTAINERS   
----------------------------------------------------------*/

/* moving centered css page when scrollbars appear fix */
html { height: 100% } 
body { min-height: 101%; }

body
{
	font-size: .75em;
	font-family: Verdana, Helvetica, Sans-Serif;
	margin: 0;
	padding: 0;
}

.page
{
	width: 1000px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

#page-container
{
	width: 890px; /* same as header.jpg */
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 10px;
}

.left-shadow, .right-shadow, .bottom-shadow
{
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.left-shadow
{
	background-image: url(./images/shadow-left.jpg);
	background-repeat: repeat-y;
	background-position: left;
}

.right-shadow
{
	background-image: url(./images/shadow-right.jpg);
	background-repeat: repeat-y;
	background-position: right;
	padding-top: 15px;
}

.bottom-shadow
{
	width: 924px;
	background-image: url(./images/shadow-bottom.jpg);
	background-repeat: no-repeat;
	background-position: center bottom;
	padding-bottom: 8px;
}

#content-container
{
	color: #4c4c4c;
	background-color: #fff;
	margin-left: 190px;
	min-height: 450px;
	display: block;
	padding-left: 10px;
	padding-bottom: 20px;
	width: 690px;
}

.content
{
	margin-left: 15px;
}

.content li
{
	margin-bottom: 8px;
}

/* CORE ELEMENTS   
----------------------------------------------------------*/
a 
{
	outline: none;
	font-family: Verdana, Helvetica, Sans-Serif;
}

/* PRIMARY LAYOUT ELEMENTS   
----------------------------------------------------------*/

h1, h2, h3, h4, h5, h6
{
	font-size: 1.5em;
	color: #000;
	font-family: Arial, Helvetica, sans-serif;
}

h1
{
	font-size: 2em;
	margin-bottom: 25px !important;
}
h2
{
	font-size: 1.3em;
	color: #691e15;
}
h3
{
	font-size: 1.1em;
	margin-bottom: 0px;
}
h4
{
	font-size: 1.0em;
}
h5, h6
{
	font-size: 1em;
}

table
{
	padding-bottom: 20px;
}

/* this rule styles <h2> tags that are the 
first child of the left and right table columns */
.rightColumn > h1, .rightColumn > h2, .leftColumn > h1, .leftColumn > h2
{
	margin-top: 0;
}


#serviceslist dt
{
	font-weight: bold;
	margin-bottom: 5px;
}

#serviceslist dd
{
	margin-bottom: 10px;
}


/* GALLERY   
----------------------------------------------------------*/

.gallerycontainer
{
	display: block;
}


.gallerycontainer a
{
	text-decoration: none;
	padding: 0px;
	margin: 0px;
	color: #fff;
	line-height: 0px;
}

.gallerycontainer a img
{
	max-height: 250px;
	max-width: 320px;
	border: solid 1px White;
	margin: 0px;
}

.gallerythumbcontainer
{
	float: left;
	margin: 5px;
	width: 326px;
	height: 280px;
	display: block;
	background: transparent url(./images/polaroid.jpg) no-repeat scroll 0 0;
}

.gallerythumbcontainer:hover
{
	background: transparent url(./images/polaroid-hover.jpg) no-repeat scroll 0 0;
}

.gallerythumb
{
	margin-top: 5px;
}

.gallerycaption
{
	padding-top: 3px;
	font-weight: bold;
	text-align: center;
}


.prevpagelink,
.nextpagelink
{
	margin: 10px;
}


/* FORM LAYOUT ELEMENTS   
----------------------------------------------------------*/

fieldset
{
	margin: 1em 0;
	padding: 1em;
	border: 1px solid #CCC;
}

fieldset p
{
	margin: 2px 12px 10px 10px;
}

fieldset label
{
	float: left;
	text-align: right;
	min-width: 160px;
	margin-right: 5px;
    padding-top:0.2em;
}

fieldset label.inline
{
	display: inline;
}

legend
{
	font-size: 1.1em;
	font-weight: bold;
	padding: 2px 4px 8px 4px;
}

input[type="text"],
input[type="password"],
textarea
{
	min-width: 200px;
	border: 1px solid #CCC;
	vertical-align: middle;
}

.form-field-aligned
{
	margin-left: 165px;
}

/* MISC  
----------------------------------------------------------*/
.clear
{
	clear: both;
}

.error
{
	color: Red;
}

div#title
{
	display: block;
	text-align: left;
}

#logindisplay
{
	font-size: 1.1em;
	display: block;
	text-align: right;
	color: White;
}

#logindisplay a:link
{
	color: white;
	text-decoration: underline;
}

#logindisplay a:visited
{
	color: white;
	text-decoration: underline;
}

#logindisplay a:hover
{
	color: white;
	text-decoration: none;
}

.field-validation-error
{
	color: #ff0000;
}

.input-validation-error
{
	border: 1px solid #ff0000;
	background-color: #ffeeee;
}

.validation-summary-errors
{
	font-weight: bold;
	color: #ff0000;
}

#services-content img
{
	color: #691e15;
	border: none;
}

.center
{
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.no-border
{
	border-width: 0;
}

.no-spacing
{
	margin: 0;
	padding: 0;
}

.center-content
{
	text-align: center;
}

/* jquery UI customizations */

.ui-datepicker-trigger 
{
	vertical-align: middle;
	margin-left: 3px;
	padding: 0;
	cursor: pointer;
}

.ui-datepicker-trigger img
{
	margin: 0 ! important;
	border: none;
}

/* Form Table */

.form-table tr > td:first-child
{
	text-align:right;
	vertical-align: middle;
	margin-right: 5px;
}

.form-table td
{
	padding-top:5px;
}