.title
{
  background-image: url(../images/bar.jpg);
  background-repeat: repeat-x;
  text-transform: uppercase;
  color: White;
  padding: 6px 0px 6px 15px;
  font-weight: bold;
  font-family: Arial;
  vertical-align: middle;
  margin-top: 1px; /* align to menu */
  margin-bottom: 10px;
}