
.team-entry
{
	margin-bottom: 50px;
	display: block;
  text-align: justify;
}

.team-entry .portrait
{
    width: 130px;
    height: auto;
    float: right;
    margin: 0px 15px 10px 10px;
}