#footer
{
	color: Gray;
	padding: 10px 0;
	text-align: center;
	line-height: normal;
	margin: 0;
	font-size: .8em;
}

#footer a
{
	outline: none;
	color: Gray;
}